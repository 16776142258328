import { debounce, pauseAllMedia, type uCoastWindow } from '@/scripts/setup'
import { elementPropertyRequired, qsaRequired, qsOptional, qsRequired } from '@/scripts/functions'
import { SliderComponent } from '@/scripts/media/sliders'
import { type DeferredMedia } from '@/scripts/media/deferred-media'
import { type StickyHeader } from '@/scripts/layout/StickyHeader'
import { type SlideChangedEvent } from '@/scripts/types/events'
import { UcoastEl } from '@/scripts/core/UcoastEl'

declare let window: uCoastWindow

export class MediaGallery extends UcoastEl {
	static htmlSelector = 'media-gallery'
	elements: {
		liveRegion: HTMLElement
		viewer: HTMLElement | SliderComponent
		thumbnails?: SliderComponent
	}
	mql: MediaQueryList
	stickyHeader?: StickyHeader
	constructor() {
		super()
		this.elements = {
			liveRegion: qsRequired('[id^="GalleryStatus"]', this),
			viewer: qsRequired('[id^="GalleryViewer"]', this) as SliderComponent | HTMLElement,
			thumbnails: qsOptional('[id^="GalleryThumbnails"]', this),
		}
		this.mql = window.matchMedia('(min-width: 750px)')
		if (!this.elements.thumbnails) return

		this.elements.viewer.addEventListener(
			'slideChanged',
			debounce(this.onSlideChanged.bind(this), 500)
		)
		qsaRequired('[data-target]', this.elements.thumbnails).forEach(
			(mediaToSwitch: HTMLElement) => {
				qsRequired('button', mediaToSwitch).addEventListener(
					'click',
					this.setActiveMedia.bind(this, mediaToSwitch.dataset.target, false)
				)
			}
		)
		const desktopLayout = this.dataset.desktopLayout
		if (desktopLayout && desktopLayout.includes('thumbnail') && this.mql.matches)
			this.removeListSemantic()
	}

	onSlideChanged(event: SlideChangedEvent) {
		if (!this.elements.thumbnails || !event.detail.currentElement) return
		const thumbnail = qsOptional(
			`[data-target="${event.detail.currentElement.dataset.mediaId}"]`,
			this.elements.thumbnails
		)
		if (!thumbnail) return
		this.setActiveThumbnail(thumbnail)
	}

	setActiveMedia(mediaId: string, prepend: boolean) {
		const activeMedia = qsRequired(`[data-media-id="${mediaId}"]`, this.elements.viewer)
		this.elements.viewer.querySelectorAll('[data-media-id]').forEach((element) => {
			element.removeAttribute('data-uc-is-active')
		})
		activeMedia.setAttribute('data-uc-is-active', '')
		const activeMediaParent = elementPropertyRequired(activeMedia, 'parentElement')

		if (prepend) {
			activeMediaParent.prepend(activeMedia)
			if (this.elements.thumbnails) {
				const activeThumbnail = qsRequired(
					`[data-target="${mediaId}"]`,
					this.elements.thumbnails
				)
				const activeThumbnailParent = elementPropertyRequired(
					activeThumbnail,
					'parentElement'
				)
				activeThumbnailParent.prepend(activeThumbnail)
			}
			if (this.elements.viewer instanceof SliderComponent) this.elements.viewer.resetPages()
		}

		this.preventStickyHeader()
		window.setTimeout(() => {
			if (this.elements.thumbnails) {
				activeMediaParent.scrollTo({ left: activeMedia.offsetLeft })
			}
			if (!this.elements.thumbnails || this.dataset.desktopLayout === 'stacked') {
				activeMedia.scrollIntoView({ behavior: 'smooth' })
			}
		})
		this.playActiveMedia(activeMedia)

		if (!this.elements.thumbnails) return
		const activeThumbnail = qsRequired(`[data-target="${mediaId}"]`, this.elements.thumbnails)
		const position = activeThumbnail.dataset.mediaPosition
		if (!position) throw 'position not found, error in setActiveMedia'
		this.setActiveThumbnail(activeThumbnail)
		this.announceLiveRegion(activeMedia, position)
	}

	setActiveThumbnail(thumbnail: HTMLElement) {
		if (!this.elements.thumbnails || !thumbnail) return

		this.elements.thumbnails
			.querySelectorAll('button')
			.forEach((element) => element.removeAttribute('aria-current'))
		qsRequired('button', thumbnail).setAttribute('aria-current', 'true')
		if (this.elements.thumbnails.isSlideVisible(thumbnail, 10)) return

		this.elements.thumbnails.slider.scrollTo({ left: thumbnail.offsetLeft })
	}

	announceLiveRegion(activeItem: HTMLElement, position: string) {
		const image = qsRequired<HTMLImageElement>('.product__modal-opener--image img', activeItem)

		image.onload = () => {
			this.elements.liveRegion.setAttribute('aria-hidden', 'false')
			this.elements.liveRegion.innerHTML = window.accessibilityStrings.imageAvailable.replace(
				'[index]',
				position
			)
			setTimeout(() => {
				this.elements.liveRegion.setAttribute('aria-hidden', 'true')
			}, 2000)
		}
		image.src = image.src // no idea why shopify is doing this but probably some reload voodoo in shopify
	}

	playActiveMedia(activeItem: HTMLElement) {
		pauseAllMedia()
		const deferredMedia = qsOptional<DeferredMedia>('.deferred-media', activeItem)
		if (deferredMedia) deferredMedia.loadContent(false)
	}

	preventStickyHeader() {
		this.stickyHeader = this.stickyHeader || qsOptional('sticky-header')
		if (!this.stickyHeader) return
		this.stickyHeader.dispatchEvent(new Event('preventHeaderReveal'))
	}

	removeListSemantic() {
		if (!(this.elements.viewer instanceof SliderComponent)) return
		this.elements.viewer
		this.elements.viewer.slider.setAttribute('role', 'presentation')
		this.elements.viewer.sliderItems.forEach((slide) =>
			slide.setAttribute('role', 'presentation')
		)
	}
}
